/* styles/Carousel.css */

.carousel-container {
  position: relative; /* 점 인디케이터 위치 조정을 위해 relative 설정 */
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto 40px auto;
}

.mainSwiper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.swiper-wrapper {
  display: flex;
  align-items: center; /* 슬라이드의 수직 중앙 정렬 */
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  transform: scale(0.8);
  transition: transform 0.3s, opacity 0.3s;
  position: relative; /* 클릭 영역을 정확히 계산하기 위해 relative 설정 */
}

.swiper-slide-active {
  opacity: 1;
  transform: scale(1);
}

.swiper-slide-prev,
.swiper-slide-next {
  opacity: 0.5;
  transform: scale(0.7);
}

.slide-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.carousel-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
  transition: transform 0.3s, opacity 0.3s;
  display: block;
  margin: auto;
  
  /* 이미지 선택 및 복사 방지 */
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+/Edge */
  user-drag: none;
  -webkit-user-drag: none;
  pointer-events: auto;

  /* 포커스 아웃라인 제거 */
  outline: none;
  border: none;

  /* 클릭 시 하이라이트 제거 (모바일) */
  -webkit-tap-highlight-color: transparent;
}

.carousel-image:focus {
  outline: none;
}

.carousel-image:active {
  outline: none;
  border: none;
}

.carousel-image::-moz-selection {
  background: transparent;
}

.carousel-image::selection {
  background: transparent;
}

/* 점 인디케이터 스타일 */
.carousel-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* 점 간의 간격 */
  width: 100%;
  max-width: 200px; /* 필요에 따라 조정 */
  margin: 0 auto;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: rgba(194, 201, 207, 0.686);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #D08C95;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .dot {
    width: 10px;
    height: 10px;
  }
}
