/* src/styles/GalleryPage.css */

.gallery-page {
  position: relative;
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
}

.back-button {
  position: fixed;
  top: 20px;
  left: 20px;
  background-color: #fff;
  border: none;
  font-size: 18px;
  cursor: pointer;
  z-index: 1000;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 항상 3열 유지 */
  gap: 10px;
  margin-top: 60px; /* 뒤로가기 버튼 공간 확보 */
}

.image-item {
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.image-item:hover img {
  transform: scale(1.05);
}

/* 모달 스타일 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 0;
  width: 75vw; /* 화면 너비의 75% */
  height: 70vh; /* 화면 높이의 90% */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* 이미지가 모달 내에서 잘리지 않고 전체가 보이도록 설정 */
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  max-width: 100%;
  object-fit: contain;
}

/* Swiper 좌우 버튼 스타일 */
.swiper-button-prev,
.swiper-button-next {
  color: #f1eadd; /* 버튼 색상 조정 */
  width: 40px;
  height: 60px;
  /* background-color: rgba(255, 255, 255, 0.7); */
}

/* 반응형 디자인 제거 */
/* @media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .image-grid {
    grid-template-columns: 1fr;
  }

  .back-button {
    top: 10px;
    left: 10px;
    padding: 8px 12px;
    font-size: 16px;
  }

  .modal {
    width: 95vw;
    height: 95vh;
  }
} */
