html { 
    -webkit-text-size-adjust: none; 
}
/* Arial */

@font-face {
    font-family: "Golden Plains";
    font-weight: normal;
    src: url("../fonts/Golden Plains.ttf") format("truetype");
}

@font-face {
    font-family: "NanumSquareRoundB";
    font-weight: normal;
    src: url("../fonts/NanumSquareRoundB.ttf") format("truetype");
}

@font-face {
    font-family: "NanumGothic";
    font-weight: normal;
    src: url("../fonts/NanumGothic.ttf") format("truetype");
}

@font-face {
    font-family: "MaruBuri";
    font-weight: normal;
    src: url("../fonts/MaruBuri.ttf") format("truetype");
}


/* 고정 Navbar 스타일 */
.fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* 다른 요소들보다 위에 표시되도록 */
}

.navbar-right {
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.nav-button {
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    color: #333333;
    font-family: 'MaruBuri';
    white-space: nowrap;
    font-weight: 700;
}

.separator {
    color: #c1c1c1;
    margin: 0 5px;
}


/* 재생/정지 버튼 스타일 */
.play-pause-button {
    background: none;
    border: none;
    margin-top: 5px;
}

body {
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;
    max-width: 800px;
}

::-webkit-scrollbar {
    display: none;
}

.section-title {
    font-family: 'Golden Plains';
    font-size: 20px;
    color: #3a8ac4;
    text-align: center;
    margin-top: 50px;
}

/* body */
.body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-bottom: 50px; */
    overflow-x: hidden;
    padding-top: 1px;
}

.body-top {
    width: 100%;
    max-width: 800px; /* 최대 너비 설정 */
    margin: 50px 0 0 0;
}

.body-top img {
    width: 100%;
    height: auto;
    display: block;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0));
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0)); /* Safari support */
}

.date {
    margin: 30px auto 20px auto; /* 상단 여백 추가 */
    width: 100%;
    color: #000000;
    text-align: center;
}

.date h1 {
    font-family: 'MaruBuri';
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
}

.body-middle {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
}

.location {
    margin-top: 30px;
    line-height: 0.8;
}


.main-word {
    width: 100%;
    text-align: center;
    font-family: 'MaruBuri';
}

.main-word p {
    font-family: 'MaruBuri';
    color: #282828;
    font-size: 0.9rem;
    letter-spacing: 2px;
    line-height: 2.2;
    font-weight: 500;
}

.body-footer {
    font-family: 'MaruBuri';
    font-weight: 500;
    margin: 30px auto 0 auto;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
}

.row1, .row2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; /* 세로 정렬 추가 */
    line-height: 30px;
    margin-bottom: 10px; /* 행 간 간격 추가 */
}

.parents {
    font-size: 1rem;
}

.bulit {
    font-size: 1rem;
    margin: 0 5px; /* 점 간 간격 조정 */
}

.groom {
    /* groom 클래스 스타일링 추가 가능 */
}

.row1 p, .row2 p {
    color: #282828;
    font-size: 0.7rem;
    line-height: 0.8;
    letter-spacing: 4px;
    margin: 0 5px; /* 텍스트 간 간격 조정 */
}

/* our-story-section */
.our-story-section {
    width: 100%;
    max-width: 800px;
    margin: 20px auto; /* 상단과 하단에 여백 추가 */
}

.our-story1-img,
.our-story2-img,
.our-story3-img,
.our-story4-img,
.our-story5-img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto; /* 이미지 간 간격 조정 */
    /* opacity: 0.9; */
}

.our-story {
    text-align: center;
    margin-bottom: 40px;
}

.our-story h1 {
    font-family: 'MaruBuri';
    margin-top: 20px;
    font-size: 1.3rem;
    letter-spacing: 4px;
    font-weight: bold;
}

.our-story p {
    font-family: 'MaruBuri';
    color: #282828;
    font-size: 0.9rem;
    letter-spacing: 2px;
    margin: 10px 0;
    line-height: 2.2;
    font-weight: 500;
}

/* toggle button */
.toggle-story-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #ff69b4; /* 원하는 색상으로 변경 가능 */
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 60px;
}

.account-Box {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0 auto;
    padding: 10px 0 0 0;
    background: none; /* 배경색 제거 */
    border: none; /* 테두리 제거 */
    position: relative;
    white-space: nowrap;
    flex-direction: column;
}

.account-Box .line {
    flex: 1;
    height: 1px; /* 선의 두께 */
    background-color: #ff85c1; /* 선의 색상 핑크 */
    margin: 10px 3%; /* 텍스트와 선 사이 간격 */
}

.account-Box .info-title {
    margin: 25px 0;
    font-size: 18px;
    font-family: 'MaruBuri';
    font-weight: 500;
}

.account-Box .info-text {
    margin-top: 10px;
    text-align: center;
    font-size: 15px;
    font-family: 'MaruBuri';
    font-weight: 500;
}

.address {
    width: 90%;
    text-align: center;
    line-height: 0.7;
}

.address h4 {
    margin-top: 20px;
    font-family: 'MaruBuri';
}

.address p {
    margin-top: 15px;
    font-family: 'MaruBuri';
    font-size: 14px;
}

.address-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto 50px auto;
    padding: 8px 8px;
    background: none;
    border:#c1c1c1 1px solid;
    cursor: pointer;
    position: relative;
    font-size: 12px;
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
    color: #424242 !important; /* ios에서 파란색으로 렌더링됨 */
    appearance: none;
}

/* 반응형 디자인 */
@media (min-width: 800px) {
    body {
        max-width: 800px;
        margin: 0 auto;
    }
    .main-word p {
        font-size: 1rem;
    }
    .our-story h1 {
        font-size: 1.4rem;
    }
    .our-story p {
        font-size: 1rem; 
    }
    .date h1 {
        font-size: 1.5rem;
    }
}
