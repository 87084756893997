/* Account.css */

.account-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
}

.account-section {
  margin-bottom: 15px;
  position: relative; /* 드롭다운 위치 조정을 위해 추가 */
}

.account-toggle-button {
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* 아이콘 절대 위치를 위해 상대 위치 설정 */
  transition: background-color 0.3s ease;
}

.account-toggle-button.open .toggle-icon {
  transform: rotate(180deg);
}

.button-text {
  width: 100%;
  text-align: center;
  z-index: 1;
  color: #424242 !important; /* ios에서 파란색으로 렌더링됨 */
  appearance: none;
}

.toggle-icon {
  position: absolute;
  right: 10px;
  width: 20px; /* 아이콘 크기 조정 */
  height: 20px;
  transition: transform 0.3s ease;
}

.account-toggle-button:focus {
  outline: none;
}

.accounts-list {
  width: 90%;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.accounts-list.open {
  max-height: 1000px; /* 충분히 큰 값으로 설정하여 내용이 모두 표시되도록 함 */
  opacity: 1;
  margin-top: 10px;
}

.accounts-list.closed {
  max-height: 0;
  opacity: 0;
  margin-top: 0;
}

.account-item {
  display: flex;
  flex-direction: row; /* 행 방향으로 정렬 */
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;
  transform: translateY(0);
}

.account-item:not(:first-child) {
  margin-top: 10px;
}

.account-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
}

.account-name {
  font-weight: bold;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.account-number {
  font-size: 15px;
  color: #000000;
}

.account-actions {
  display: flex;
  flex-direction: column; /* 세로 방향으로 정렬 */
  align-items: flex-start;
  width: 75px;
}

.kakao-pay-button {
  background-color: #FFEB00; 
  border: none;
  border-radius: 4px;
  font-weight: bold;
  padding: 6px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.kakaoPay-icon {
  border-radius: 5px;
  width: 50px;
  height: auto;
}

.copy-button {
  background-color: #fff; /* 흰색 배경 */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  color: #424242 !important;
  width: 100%;
}

.copy-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

/* 애니메이션 추가 */
@keyframes dropdownOpen {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
}

@keyframes dropdownClose {
  from {
    max-height: 1000px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}
