/* KakaoShare.css */

.kakao-share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  margin-top: 60px;
  margin-bottom: 20px;
  border-radius: 10px;
  color: #424242 !important;
}


.kakao-share-button img {
  width: 24px;
  margin-right: 3px;
  vertical-align: middle;
}

.kakao-share-button span {
  vertical-align: middle;
}
